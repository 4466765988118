<template>
    <HeaderMobile
        :fixed="isDetailsHeader"
        :transparent="isDetailsHeader && !isScrolled"
        :non-printable="isDetailsHeader"
        id="headerMobile"
        ref="header"
        class="main-header-mobile"
        :class="{ 'main-header-mobile--scrolled': isScrolled }"
    >
        <template #left>
            <template v-if="showNavigationComputed">
                <slot name="burger" v-bind:showMenu="showMenu">
                    <button
                        slot="burger"
                        id="burgerButton"
                        class="main-header-mobile__menu-button main-header-mobile__button navbar-toggler"
                        type="button"
                        aria-controls="navigation"
                        :aria-expanded="showMenu"
                        :aria-label="$t('Header.toggleNavigation')"
                        @click.stop="showMenu = true"
                    >
                        <span class="main-header-mobile__menu-button-container center-content">
                            <IconSvg filepath="burger.svg" />
                        </span>
                    </button>
                </slot>

                <!-- keep alive to prevent from computing active on each menu open -->
                <DialogSwipe :show="showMenu" @close="showMenu = false" class="main-header-mobile__mobile-menu">
                    <NavigationMobile @close="showMenu = false" />
                </DialogSwipe>
            </template>
            <!--Back arrow and share-->
            <template v-else>
                <slot v-if="$route.name !== 'index'" name="back" v-bind:goBack="goBack">
                    <button
                        @click.prevent="goBack"
                        class="btn main-header-mobile__button main-header-mobile__back-button center-content"
                        :aria-label="$t('Generic.toStartPage')"
                        ref="back"
                    >
                        <IconSvg filepath="back.svg" />
                    </button>
                </slot>
                <button
                    v-if="isDetailsHeader"
                    @click="share"
                    class="btn main-header-mobile__button main-header-mobile__share-button center-content"
                    :aria-label="$t('Generic.share')"
                >
                    <IconSvg filepath="share.svg" />
                </button>
            </template>
        </template>

        <template #center>
            <h3 v-if="!ignoreTitle && title" class="header-title">
                {{ title }}
            </h3>
            <template v-else-if="!isDetailsHeader">
                <keep-alive>
                    <div v-if="!searchActive" class="logo-container" :class="{ 'flex-wrap': !isCooperationHeader }">
                        <slot name="logo" v-bind:isCooperationHeader="isCooperationHeader">
                            <template v-if="isCooperationHeader && platformLogoUrl">
                                <Logo class="main-logo partner-logo" :src="platformLogoUrl" :href="platformLogoHref" />
                                <div class="logo-separator"></div>
                            </template>

                            <Logo
                                :cooperation-text="isCooperationHeader"
                                class="main-logo"
                                :class="{ 'coop-logo': isCooperationHeader }"
                                :href="platformLogoHref"
                            />

                            <component :is="headingTag" v-if="!isCooperationHeader" class="main-header"
                                >{{ $t('Header.mobile.text') }}
                            </component>
                        </slot>
                    </div>

                    <Autocomplete
                        v-else
                        :placeholder="$t('Header.mobile.autocomplete')"
                        :value.sync="searchTerm"
                        class="autocomplete-mobile"
                        clearable
                        ref="autocomplete"
                    >
                        &#8203;
                        <!--cleaning up slot content-->
                    </Autocomplete>
                </keep-alive>
            </template>
        </template>

        <template #right>
            <slot name="right">
                <button
                    v-if="isSearchVisible && showNavigationComputed"
                    class="btn main-header-mobile__button main-header-mobile__search-button center-content"
                    @click="toggleSearch"
                    :aria-label="$t('SearchBar.autocomplete.placeholder')"
                >
                    <IconSvg v-show="!searchActive" filepath="search/magnifier.svg" />
                    <IconSvg v-show="searchActive" class="search-button__icon-off" filepath="search/search-off.svg" />
                </button>

                <Favorite
                    v-else-if="isDetailsHeader && product && $route.name !== 'hotelDetails'"
                    tag="button"
                    :product="product"
                    class="btn main-header-mobile__favorite center-content"
                />
            </slot>
        </template>
    </HeaderMobile>
</template>

<script>
import NavigationMobile from '@/components/navigation/NavigationMobile';
import Logo from '@/components/Logo';
import Autocomplete from '@/components/search/Autocomplete';
import Favorite from '@/components/grid/Favorite';
import FavoriteClass from '@/models/pre-ts/customer/Favorite';
import headerCommon from '@/mixins/headerCommon';
import HeaderMobile from '@/components/mobile/HeaderMobile';
import DialogSwipe from '@/components/mobile/DialogSwipe.vue';

export default {
    name: 'MainHeaderMobile',
    mixins: [headerCommon],
    data() {
        return {
            showMenu: false,
            isDetailsHeader: false,
            disableTransparentOffset: 150,
            searchActive: !!this.$route.query.q,
            searchTerm: this.$route.query.q,
            isScrolled: false
        };
    },
    components: {
        DialogSwipe,
        Favorite,
        NavigationMobile,
        Logo,
        Autocomplete,
        HeaderMobile
    },
    props: {
        showNavigation: Boolean,
        /**
         * @property Controls if header will be transparent on details pages
         * */
        changeOnDetails: {
            type: Boolean,
            default: true
        },
        ignoreTitle: Boolean
    },
    computed: {
        showNavigationGlobal() {
            return this.$store.state.showNavigation;
        },
        showNavigationComputed() {
            return this.showNavigationGlobal && this.showNavigation;
        },
        title() {
            return this.$store.state.mobileHeaderTitle;
        },
        isSearchVisible() {
            //when header title is set, no autocomplete for search is shown
            return this.$store.state.globalMeta.searchBarVisible && !this.title;
        },
        product() {
            const product = this.$store.state.product;
            const productDetails = product && (product.productDetails || product.voucherDetails);
            return productDetails || {};
        }
    },
    methods: {
        share(e) {
            /*
                    Web Share Api
                    Instagram sharing should be done natively
                 */
            if (navigator.share) {
                window.navigator
                    .share({
                        title: document.title,
                        text: document.title,
                        url: location.href
                    })
                    .then(() => {
                        //shared
                    })
                    .catch(err => {
                        //failed
                    });
            } else {
                this.$store.commit('setShowShare', true);
            }
        },
        onDetailsScroll() {
            this.isScrolled = window.pageYOffset >= this.disableTransparentOffset;
        },
        goBack() {
            if (this.$getSafe(window, 'history.length') > 1) {
                this.$router.go(-1);
            } else {
                this.$router.replace('/');
            }
        },
        toggleSearch() {
            this.searchActive = !this.searchActive;
        },
        toggleBackButton() {
            this.$nextTick(() => {
                if (this.$refs.back && process.client) {
                    // Navigation API is not available in e.g. FF
                    const isLegacyBrowser = typeof window.Navigation !== 'function';
                    const cannotGoBackOrToBlankTab = window.history.length < 2;
                    if ((!isLegacyBrowser && !window.navigation.canGoBack) || cannotGoBackOrToBlankTab) {
                        this.$refs.back.style.display = 'none';
                    } else {
                        this.$refs.back.style.display = '';
                    }
                }
            });
        }
    },
    watch: {
        $route: {
            handler(route) {
                if (this.changeOnDetails) {
                    this.isDetailsHeader = route.meta.isDetailsRoute;
                    if (process.client) {
                        if (this.isDetailsHeader) {
                            window.addEventListener('scroll', this.onDetailsScroll);
                            this.$on('hook:mounted', this.onDetailsScroll);
                        } else {
                            window.removeEventListener('scroll', this.onDetailsScroll);
                        }
                    }
                }

                this.toggleBackButton();
            },
            immediate: true
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onDetailsScroll);
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';

.main-header-mobile {
    background: var(--header-mobile-background);
    color: var(--header-mobile-color);

    &__button {
        &:focus,
        &:active,
        &:hover {
            outline: none;
            box-shadow: none;
            color: currentColor;
        }
    }

    &__menu-button {
        font-size: 20px;
        display: flex;
        padding: 5px;
    }

    &__menu-button-container {
        flex: auto;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--border-color);
        background: var(--mobile-drawer-bg);
        color: var(--mobile-drawer-color) !important;
    }

    &__search-button {
        display: flex;
        margin-left: auto;
        align-items: center;
        font-size: 16px;

        &__icon-off {
            font-size: 20px;
        }
    }

    &__favorite {
        display: flex;
    }

    .header-mobile-right {
    }

    &__back-button,
    &__share-button,
    &__favorite {
        font-size: 24px;
        line-height: 1;
        color: currentColor !important;
    }

    &__mobile-menu {
        background-color: variables.$mobile-menu-mask-background;
    }
}

#headerMobile {
    .logo-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
        height: 100%;

        .main-logo {
            flex: 0 0 var(--logo-main-width-mobile);
            height: 100%;
            display: flex;
            align-items: center;
            text-transform: none;
            padding: 3px 0;

            &:not(.partner-logo, .coop-logo) {
                height: calc(100% - 14px);
                flex-basis: 100%;

                ::v-deep .logo-wrapper {
                    justify-content: center;
                    //flex-wrap: wrap;

                    img {
                        max-height: 90%;
                    }
                }
            }
        }

        .main-header {
            font-size: 12px;
            line-height: 1;
            padding-bottom: 2px;
            font-weight: bold;
            white-space: nowrap;
            margin-bottom: 0;
        }

        .logo-separator {
            border-left: 1px solid var(--border-color);
            height: 80%;
            flex-basis: 1px;
            flex-grow: 0;
            margin: 0 5px;
        }

        .coop-logo {
            flex: 0 0 var(--logo-coop-width-mobile) !important;
        }
    }

    .header-title {
        margin: 0;
        font-size: inherit;
        text-transform: uppercase;
    }

    .autocomplete-mobile {
        flex-grow: 2;
        max-width: 350px;

        ::v-deep input {
            color: var(--header-mobile-color);

            &::placeholder {
                color: var(--header-mobile-color-opaque);
            }

            text-align: center;
            background-color: inherit;
        }
    }
}
</style>

<template>
    <button
        id="toTop"
        @click="scrollToTop"
        :title="$t('Generic.toTop')"
        class="d-print-none"
        :class="{ visible }"
        ref="button"
    >
        <IconSvg filepath="arrow-top.svg"> </IconSvg>
    </button>
</template>

<script>
export default {
    name: 'ToTopButton',
    data() {
        return {
            ticking: false,
            screenHeight: window.innerHeight,
            visible: false
        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto' //smooth
            });
        },
        scrollHandler() {
            if (!this.ticking) {
                window.requestAnimationFrame(() => {
                    const yOffset = window.scrollY;

                    this.visible = yOffset > this.screenHeight;

                    this.ticking = false;
                });

                this.ticking = true;
            }
        }
    },
    watch: {
        visible(visible) {
            if (!visible) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$refs.button.style.display = 'none';
                    }, 300);
                });
            } else {
                this.$refs.button.style.display = '';
            }
        }
    },
    mounted() {
        window.addEventListener('scroll', this.scrollHandler, { passive: true });
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scrollHandler, { passive: true });
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';

#toTop {
    position: fixed;
    padding: 5px 7px;
    right: 0;
    top: 50%;
    z-index: 9;
    transition: opacity ease 0.3s;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 0;
    color: rgba(variables.$muted, 0.3);
    outline: none;
    opacity: 0;

    &.visible {
        opacity: 1;
    }

    svg {
        font-size: 30px;
    }
}
</style>

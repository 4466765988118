<template>
    <header
        class="header-mobile"
        :class="{
            'header-mobile--fixed': fixed,
            'header-mobile--transparent': transparent,
            'd-print-none': nonPrintable
        }"
    >
        <div class="header-mobile__left">
            <slot name="left" />
        </div>

        <div class="header-mobile__center">
            <slot name="center" />
        </div>

        <div class="header-mobile__right">
            <slot name="right" />
        </div>
    </header>
</template>

<script>
export default {
    name: 'HeaderMobile',

    props: {
        fixed: Boolean,
        nonPrintable: Boolean,
        transparent: Boolean,
        noPadding: Boolean
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/theming' as *;

.header-mobile {
    $self: &;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: map.get(variables.$z-indices, 'header-mobile');
    box-shadow: 0 1px 5px 3px rgba(0, 0, 0, 0.1);
    padding: 0 5px;
    background: var(--header-mobile-background);
    color: var(--header-mobile-color);
    height: var(--header-mobile-height);

    &__left,
    &__right {
        min-width: 56px;
        display: flex;
        color: currentColor;

        // fixes mobile usability
        button {
            min-height: 48px;
            min-width: 48px;
        }
        /*button {
            font-size: 24px;
            line-height: 1;
            color: currentColor;
        }*/
    }

    &__left {
        justify-content: flex-start;
    }

    &__center {
        font-size: 16px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
    }

    &__right {
        justify-content: flex-end;
        margin-left: auto;
    }

    &--fixed {
        position: fixed;
    }

    &--transparent {
        background: linear-gradient(180deg, hsla(0, 0%, 0%, 0.7), transparent) !important;
        box-shadow: none;

        #{$self}__left,
        #{$self}__right {
            color: var(--white);
        }
    }
}
</style>

<template>
    <header id="header">
        <!-- Change layout according to headerVariant value (default or cooperation) -->
        <div id="mainHeader" class="container">
            <div class="d-flex align-items-end">
                <NettoLogo />
            </div>
        </div>
        <!--
            the following logos should be thought out with responsive breakpoints
        -->
    </header>
</template>

<script>
import NettoLogo from '@/themes/nettovorteilswelt/components/NettoLogo';

export default {
    name: 'NettoHeader',
    components: {
        NettoLogo,
    },
    computed: {
        platformLogoUrl() {
            return this.$store.state.globalMeta.platformLogoUrl;
        },
        supportPhone() {
            return this.globalMeta.supportPhoneNumber || '030 606 900 20';
        },
        globalMeta() {
            return this.$store.state.globalMeta;
        },
        joinedOfficeHours() {
            if (!this.globalMeta.officeHours) return '';
            const [from, until] = [...this.globalMeta.officeHours];
            return `${from}-${until} ${this.$t('Generic.hour', null, 1)}`;
        },
    },
};
</script>

<style scoped lang="scss">
@use "../styles/_variables" as theme;

#topBar {
    $color: hsl(0, 0%, 100%);

    background: hsl(0, 0%, 20%);
    color: $color;
    line-height: 40px;

    .bar-item {
        position: relative;

        &:not(:first-child)::before {
            border-left: 1px solid $color;
            content: '';
            position: absolute;
            height: 1em;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

#mainHeader {
    height: 120px;
    display: flex;
    align-items: center;
}
</style>

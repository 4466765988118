<template>
    <div v-if="tilesToShow" id="footerTiles" class="container-fluid">
        <div class="row justify-content-center">
            <div class="col d-none d-md-block">
                <div class="tile"></div>
            </div>
            <div v-if="adsExists" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.ads } }">
                    <span class="tile-text ads">Aktuelle Werbung</span>

                    <svg class="icon-promotions">
                        <use :xlink:href="promotionsIcon + '#icon_promotions'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="multimediaAndFreetimeExist" class="col item">
                <nuxt-link
                    class="tile item"
                    :to="{ name: 'category', params: { category: categories.multimediaAndFreetime } }"
                >
                    <span class="tile-text">Multimedia & Freizeit</span>
                    <svg class="icon-film-freetime">
                        <use :xlink:href="filmIcon + '#icon_film-freetime'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="freetimeExists" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.freetime } }">
                    <span class="tile-text">Film & Freizeit</span>
                    <svg class="icon-film-freetime">
                        <use :xlink:href="filmIcon + '#icon_film-freetime'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="ticketsExits" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.tickets } }">
                    <span class="tile-text">Tickets & Events</span>
                    <svg class="icon-tickets">
                        <use :xlink:href="ticketsIcon + '#icon_tickets'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="autoExists" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.auto } }">
                    <span class="tile-text car">Auto & Mobiles</span>
                    <svg class="icon-car">
                        <use :xlink:href="carIcon + '#icon_car'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="lottoExists" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.lotto } }">
                    <span class="tile-text">Lotto & Co.</span>
                    <svg class="icon-lottery">
                        <use :xlink:href="lotteryIcon + '#icon_lottery'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="insurancesExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.insurances } }">
                    <span class="tile-text">Versicherungen</span>
                    <svg class="icon-insurance">
                        <use :xlink:href="insuranceIcon + '#icon_insurance'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="freeDealsExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.freeDeals } }">
                    <span class="tile-text">Gratis Deals</span>
                    <svg class="icon-free-deals">
                        <use :xlink:href="freeDealsIcon + '#icon_free-deals'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="mobileDealsExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.mobileDeals } }">
                    <span class="tile-text">Mobiles</span>
                </nuxt-link>
            </div>
            <div v-if="leisureParksExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.leisureParks } }">
                    <span class="tile-text">Freizeitparks</span>
                    <svg class="icon-leisure-parks">
                        <use :xlink:href="leisureParksIcon + '#icon_leisure-parks'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="biddingOffersExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.biddingOffers } }">
                    <span class="tile-text">Wunschpreis</span>
                    <svg class="icon-bidding-offers">
                        <use :xlink:href="biddingOffersIcon + '#icon_bidding-offers'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div v-if="discountDealsExist" class="col item">
                <nuxt-link class="tile item" :to="{ name: 'category', params: { category: categories.discountDeals } }">
                    <span class="tile-text">%-Deals</span>
                    <svg class="icon-discount-deals">
                        <use :xlink:href="discountDealsIcon + '#icon_discount-deals'"></use>
                    </svg>
                </nuxt-link>
            </div>
            <div class="col d-none d-md-block">
                <div class="tile"></div>
            </div>
        </div>
    </div>
</template>

<script>
import promotionsIcon from '../assets/icons/icon_promotions.svg';
import filmIcon from '../assets/icons/icon_film-free-time.svg';
import ticketsIcon from '../assets/icons/icon_tickets.svg';
import carIcon from '../assets/icons/icon_car.svg';
import lotteryIcon from '../assets/icons/icon_lottery.svg';
import insuranceIcon from '../assets/icons/icon_insurance.svg';
import freeDealsIcon from '../assets/icons/icon_free-deals.svg';
import leisureParksIcon from '../assets/icons/icon_leisureparks.svg';
import biddingOffersIcon from '../assets/icons/icon_bidding-offers.svg';
import discountDealsIcon from '../assets/icons/icon_discount-deals.svg';

export default {
    name: 'FooterTiles',
    data() {
        return {
            categories: {
                ads: 'aktuelle-werbung',
                freetime: 'film-und-freizeit',
                tickets: 'tickets-und-events',
                auto: 'auto-und-mobiles',
                lotto: 'lotto-und-co',
                insurances: 'versicherungen',
                freeDeals: 'gratis-deals',
                leisureParks: 'freizeitparks',
                mobileDeals: 'mobiles',
                multimediaAndFreetime: 'multimedia-und-freizeit',
                biddingOffers: 'wunschpreis',
                discountDeals: 'prozent-deals'
            },
            promotionsIcon,
            filmIcon,
            ticketsIcon,
            carIcon,
            lotteryIcon,
            insuranceIcon,
            freeDealsIcon,
            leisureParksIcon,
            biddingOffersIcon,
            discountDealsIcon
        };
    },
    computed: {
        entries() {
            return this.$store.state.globalMeta.navigationEntries || [];
        },
        adsExists() {
            return this.entryExists(this.categories.ads);
        },
        freetimeExists() {
            return this.entryExists(this.categories.freetime);
        },
        ticketsExits() {
            return this.entryExists(this.categories.tickets);
        },
        autoExists() {
            return this.entryExists(this.categories.auto);
        },
        lottoExists() {
            return this.entryExists(this.categories.lotto);
        },
        insurancesExist() {
            return this.entryExists(this.categories.insurances);
        },
        freeDealsExist() {
            return this.entryExists(this.categories.freeDeals);
        },
        leisureParksExist() {
            return this.entryExists(this.categories.leisureParks);
        },
        biddingOffersExist() {
            return this.entryExists(this.categories.biddingOffers);
        },
        discountDealsExist() {
            return this.entryExists(this.categories.discountDeals);
        },
        mobileDealsExist() {
            return this.entryExists(this.categories.mobileDeals);
        },
        multimediaAndFreetimeExist() {
            return this.entryExists(this.categories.multimediaAndFreetime);
        },
        tilesToShow() {
            return (
                this.adsExists ||
                this.freetimeExists ||
                this.ticketsExits ||
                this.autoExists ||
                this.lottoExists ||
                this.insurancesExist ||
                this.freeDealsExist ||
                this.mobileDealsExist ||
                this.multimediaAndFreetimeExist
            );
        }
    },
    methods: {
        entryExists(category) {
            try {
                return !!this.entries.find(item => {
                    if (item.link && item.link.page === category) {
                        return item;
                    }
                    if (item.children && item.children.length) {
                        return !!item.children.find(child => {
                            return child.link && child.link.page === category;
                        });
                    }
                });
            } catch (e) {
                console.log(e);
            }
            return false;
        }
    }
};
</script>

<style scoped lang="scss">
@use '../styles/_variables' as theme;
@use '@/styles/variables';
@use '@/styles/_mixins' as mixins;

#footerTiles {
    $tile-padding: 5px;
    margin-bottom: 20px;
    padding: $tile-padding;

    .row {
        margin: -$tile-padding;
    }

    .col {
        padding: $tile-padding;

        &.item {
            flex-basis: 120px;
            flex-grow: 0;
            max-width: 120px;
        }
    }

    .tile {
        padding: 10px 7px;
        background-color: theme.$secondary;
        height: 104px;
        text-align: center;
        color: theme.$primary;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .tile-text {
            @include mixins.hyphen;
            display: inline-block;
            line-height: 1.2;
            margin: auto;
            margin-top: 0;

            &.ads {
                max-width: 90%;
            }

            &.car {
                max-width: 70%;
            }
        }

        .icon-promotions {
            height: 48px;
        }
        .icon-film-freetime {
            height: 42px;
        }
        .icon-tickets {
            height: 48px;
        }
        .icon-car {
            height: 48px;
        }
        .icon-lottery {
            height: 38px;
        }
        .icon-insurance {
            height: 38px;
        }
        .icon-free-deals {
            height: 48px;
        }
        .icon-leisure-parks {
            height: 64px;
        }
    }
}
</style>

<template>
    <div id="bottomFooter" class="container-fluid text-center">
        © Netto Marken-Discount Stiftung & Co. KG
    </div>
</template>

<script>
export default {
    name: 'BottomFooter',
    components: {},
    data () {
        return {}
    },
    props: {},
    computed: {},
    methods: {}
}
</script>

<style scoped lang="scss">
@use "../styles/_variables" as theme;

#bottomFooter {
    background: theme.$primary;
    color: hsl(0, 0%, 100%);
    line-height: 56px;
}
</style>

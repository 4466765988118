<template>
    <div class="d-flex align-items-end">
        <Logo class="main-logo" :src="require('../assets/Logosnetto-logo_tile.png')" :href="platformLogoHref" />
        <button type="button" class="btn btn-primary logo-button">Vorteilswelt</button>
    </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
    name: 'NettoLogo',
    components: { Logo },
    computed: {
        platformLogoHref() {
            return this.$store.state.globalMeta.platformLogoHref;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/edeka-group/mixins';
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints';

.logo,
.logo-button {
    @include mixins.elevation;
}

.logo-button {
    margin-left: 15px;
    border-radius: 0 !important;
    line-height: 1;
    height: 32px;
    width: 130px;
    font-family: FrankfurterNetto, sans-serif;
    padding: 0;

    @include breakpoints.media-breakpoint-down(variables.$mobile-breakpoint-max) {
        height: 24px;
        min-width: 97px;
    }
}
</style>

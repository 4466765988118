<template>
    <div id="animod" :class="[priceModeCSS]" ref="nettoLayout">
        <media-only desktop>
            <NettoHeader v-if="showHeader" />
            <div v-if="showNavigation" class="nav-wrapper container-fluid">
                <Navigation />
            </div>
        </media-only>

        <media-only mobile>
            <MainHeaderMobile :showNavigation="showMobileNavigation" :change-on-details="false" ignore-title>
                <template v-slot:back="slotProps">
                    <button
                        id="backMobile"
                        type="button"
                        @click.prevent="slotProps.goBack"
                        class="left-button btn btn-link"
                        :aria-label="$t('Generic.toStartPage')"
                    >
                        <svg class="icon-collapse">
                            <use :xlink:href="leftIcon + '#icon_arrow-left'"></use>
                        </svg>
                    </button>
                </template>
                <template v-slot:logo>
                    <NettoLogo />
                </template>
            </MainHeaderMobile>
        </media-only>

        <main id="main">
            <nuxt />
        </main>

        <template v-if="!isCheckoutRoute">
            <FooterTiles />
            <SocialAndPayment hide-social class="netto-payment" />
            <NettoFooter />
        </template>

        <BottomFooter />

        <CookiesNotice v-if="showCookiesNotice" @close="showCookiesNotice = false" />

        <client-only>
            <ToTopButton />
        </client-only>
    </div>
</template>

<script>
import priceModeMixin from '@/mixins/priceModesGlobal';
import authorizedLayout from '@/mixins/authorizedLayout';
import translatableLayout from '@/mixins/translatableLayout';
import themeableLayout from '@/mixins/themeableLayout';
import NettoHeader from '@/themes/nettovorteilswelt/components/NettoHeader';
import NettoFooter from '@/themes/nettovorteilswelt/components/NettoFooter';
import MainHeaderMobile from '@/components/header/MainHeaderMobile';
import Navigation from '@/components/navigation/Navigation';
import CookiesNotice from '@/components/CookiesNotice';
import ToTopButton from '@/components/ToTopButton';
import FooterTiles from '@/themes/nettovorteilswelt/components/FooterTiles';
import BottomFooter from '@/themes/nettovorteilswelt/components/BottomFooter';
import NettoLogo from '@/themes/nettovorteilswelt/components/NettoLogo';
import SocialAndPayment from '@/components/SocialAndPayment';
import leftIcon from '@/themes/nettovorteilswelt/assets/icons/icon_arrow-left.svg';

export default {
    name: 'combined',
    head() {
        return {
            link: [
                {
                    rel: 'icon',
                    type: 'image/x-icon',
                    href: '/themes/nettovorteilswelt/favicon.ico',
                    hid: 'favicon',
                    sizes: 'any'
                },
                { rel: 'icon', type: 'image/svg+xml', href: '/themes/nettovorteilswelt/favicon.svg', hid: 'icon-svg' },
                {
                    rel: 'apple-touch-icon',
                    href: '/themes/nettovorteilswelt/apple-touch-icon-152x152.png',
                    hid: 'apple-touch-icon',
                    sizes: '152x152'
                }
            ]
        };
    },
    mixins: [priceModeMixin, authorizedLayout, translatableLayout, themeableLayout],
    components: {
        SocialAndPayment,
        NettoLogo,
        BottomFooter,
        FooterTiles,
        NettoHeader,
        MainHeaderMobile,
        Navigation,
        NettoFooter,
        CookiesNotice,
        ToTopButton
    },
    data() {
        return {
            leftIcon
        };
    },
    computed: {
        showMobileNavigation() {
            return (
                !this.isCheckoutRoute &&
                !!(this.navigationEntries.length || this.$getSafe(this.globalMeta, 'footerLinks.length'))
            );
        }
    }
};
</script>

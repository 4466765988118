<template>
    <div class="social-and-payment container d-print-none">
        <div class="row justify-content-center">
            <div v-if="!hideSocial" class="social-links col-sm-6">
                <span class="social-heading">
                    <slot> {{ $t('Social.doNotMiss') }}: </slot>
                </span>

                <a
                    href="https://www.facebook.com/animod.gmbh"
                    title="Animod auf Facebook"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="social-network"
                >
                    <IconSvg filepath="social/facebook.svg" />
                </a>
                <a
                    href="https://www.instagram.com/animod.de/"
                    title="Animod auf Instagram"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="social-network"
                >
                    <IconSvg filepath="social/instagram.svg" />
                </a>
                <a href="https://www.animod.de/magazin/" title="Animod Blog" target="_blank" rel="noreferrer noopener">
                    Blog
                </a>
                <a
                    href="https://www.kununu.com/de/animod"
                    title="Animod auf Kununu"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="social-network"
                >
                    <IconSvg filepath="social/kununu.svg" />
                </a>
            </div>

            <div v-if="!hidePayment" class="col-sm-6 payment-methods">
                <IconSvg
                    v-for="(iconSrc, index) in paymentIconsUnique"
                    :key="'paymentIcon' + index"
                    :filepath="iconSrc"
                    :class="'payment-methods__icon'"
                />
                <!--<IconSvg filepath="paymentMethods/payPal.svg" />
                <IconSvg filepath="paymentMethods/visa.svg" />
                <IconSvg filepath="paymentMethods/mastercard.svg" />
                <IconSvg filepath="paymentMethods/vorkasse.svg" />
                <IconSvg filepath="paymentMethods/sofortueberweisung.svg" />
                <img
                    :src="require('@/assets/icons/paymentMethods/giropay.svg')"
                    class="payment-icon icon-paydirekt"
                    alt="paydirekt"
                />-->
            </div>
        </div>
    </div>
</template>

<script>
import paymentMethods from '@/models/paymentMethods';

export default {
    name: 'SocialAndPayment',
    props: {
        hideSocial: Boolean,
        hidePayment: Boolean
    },
    computed: {
        availablePaymentMethods() {
            return this.$store.state.globalMeta.availablePaymentMethods || [];
        },
        paymentIconsUnique() {
            const iconsToShow = this.availablePaymentMethods;
            return [
                ...new Set(
                    Object.keys(paymentMethods).reduce((final, current) => {
                        if (iconsToShow.indexOf(paymentMethods[current].value) !== -1) {
                            if (paymentMethods[current].iconSrc instanceof Array) {
                                final = final.concat(paymentMethods[current].iconSrc);
                            } else if (paymentMethods[current].iconSrc) {
                                final.push(paymentMethods[current].iconSrc);
                            }
                        }
                        return final;
                    }, [])
                )
            ];
        }
    }
};
</script>

<style scoped lang="scss">
.social-and-payment {
    background-color: var(--body-bg);
    margin-top: 30px;

    .social-heading {
        margin-right: 5px;
    }

    .social-links,
    .payment-methods {
        display: flex;
        align-items: center;

        > a,
        > svg {
            display: inline-flex;
        }
    }

    .social-links {
        justify-content: flex-start;

        svg {
            font-size: 20px;
        }
    }

    .payment-methods {
        justify-content: flex-end;
        font-size: 2em;

        > svg {
            margin: 0 5px;
        }
    }

    a {
        color: var(--body-color);
        padding: 5px;
    }
}
</style>

<template>
    <dialog id="cookieNotice" class="container-fluid">
        <span>
            {{ $t('CookieNotice.text') }}
        </span>

        <button class="btn btn-info btn-sm close-button" type="button" @click="closeNotice" title="Zustimmen">
            {{ $t('Generic.agree') }}
        </button>

        <nuxt-link :to="privacyUrl" class="text-primary" target="_blank">
            <u>{{ $t('Generic.moreInformation') }}</u>
        </nuxt-link>
    </dialog>
</template>

<script>
export default {
    name: 'CookiesNotice',
    computed: {
        theme() {
            return this.$store.state.theme;
        },
        privacyUrl() {
            return { name: 'category', params: { category: 'datenschutz' } };
        }
    },
    methods: {
        closeNotice() {
            this.$cookies.cookies_notice = 'no';
            this.$emit('close');
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/theming' as *;
@use '@/styles/bootstrap/variables' as bsvars;

#cookieNotice {
    background-color: var(--footer-bg-color);
    color: var(--footer-link-color);
    position: fixed;
    z-index: bsvars.$zindex-dropdown + 1;
    padding-top: 5px;
    padding-bottom: 5px;
    bottom: 0;
    left: 0;
    height: auto;
    border: 0;
    display: block;
    text-align: center;

    > * {
        margin: 0 5px;
        display: inline-block;
        vertical-align: middle;
    }

    .close-button {
        font-size: 1em;
    }
}
</style>

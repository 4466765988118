<template>
    <footer id="footer" class="container">
        <div class="row px-md-5">
            <section v-for="(section, index) in footerLinks" :key="section.title + index" class="col-md footer-section">
                <h4
                    v-if="section.title"
                    class="footer-heading"
                    @click="$set(active, section.title, !active[section.title])"
                    :class="{ active: active[section.title] }"
                >
                    {{ section.title }}
                    <button type="button" class="btn collapse d-inline-block d-md-none">
                        <svg class="icon-collapse">
                            <use :xlink:href="arrowLeftIcon + '#icon_arrow-left'"></use>
                        </svg>
                    </button>
                </h4>
                <ul>
                    <li v-for="(item, index) in section.links" :key="item.text + index" v-bind:class="item.alignment">
                        <!--Met case when link was undefined-->
                        <Link v-if="item.link" :link="item.link" v-html="item.text"></Link>
                        <span v-else v-html="item.text"></span>
                    </li>
                </ul>
            </section>
        </div>
    </footer>
</template>

<script>
import arrowLeftIcon from '../assets/icons/icon_arrow-left.svg';

export default {
    name: 'NettoFooter',
    components: {},
    data() {
        return {
            active: {},
            arrowLeftIcon
        };
    },
    props: {},
    computed: {
        footerLinks() {
            return this.$store.state.globalMeta.footerLinks;
        }
    }
};
</script>

<style scoped lang="scss">
@use '@/styles/variables';
@use '@/styles/bootstrap/breakpoints' as breakpoints;

#footer {
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 14px;

    section {
        text-align: left;

        .footer-heading {
            margin-top: 0;
            margin-bottom: 20px;
            display: flex;
            font-size: inherit;
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                margin-bottom: 12px;

                &:only-child {
                    margin-bottom: 0;
                }

                &.right {
                    text-align: right;
                }
                &.center {
                    text-align: center;
                }
            }

            a,
            span {
                text-decoration: none;
                color: variables.$body-color;
            }
        }

        @include breakpoints.media-breakpoint-down(sm) {
            margin-bottom: 10px;

            .footer-heading {
                border-bottom: 1px solid variables.$border-color;
                font-size: 14px;
                line-height: 23px;
                font-weight: 400;
                margin-bottom: 0;

                .collapse {
                    padding: 0;
                    margin-left: auto;

                    .icon-collapse {
                        height: 24px;
                        width: 24px;
                        transform: rotate(-90deg);
                        display: block;
                    }
                }
            }

            ul {
                display: none;
                padding-top: 10px;
            }

            .footer-heading.active + ul {
                display: block;
            }
            .footer-heading.active {
                .collapse .icon-collapse {
                    transform: rotate(90deg);
                }
            }
        }
    }
}
</style>
